import React from "react";
import classes from "./Heading.module.scss";
import noise from "../../../media/noise.png";
import arrowBack from "../../../media/icons/arrow-left-white.svg";
import { useTranslation } from "react-i18next";
import { scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";

const Heading = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const goBackHandler = () => {
    navigate(-1);
  };

  const scheduleConsultationHandler = () => {
    scroller.scrollTo("form", {
      smooth: true,
      offset: -50,
      duration: 800,
    });
  };

  return (
    <div className={classes["heading-container"]}>
      <div className={`${classes["circle"]} ${classes["circle--4"]}`}></div>
      <div className={`${classes["circle"]} ${classes["circle--3"]}`}></div>
      <div className={`${classes["circle"]} ${classes["circle--2"]}`}></div>
      <div className={`${classes["circle"]} ${classes["circle--1"]}`}></div>
      <img src={noise} alt={"noise background"} className={classes["noise"]} />
      <div className={classes["back-button-container"]}>
        <div className={classes["back-button"]} onClick={goBackHandler}>
          <img src={arrowBack} alt="Arrow back" />
          <span>{t("buttons.back")}</span>
        </div>
      </div>

      <div className={classes.heading}>
        <div className={classes["text-block--300"]}>
          <span className={`${classes.text} ${classes["text--white"]}`}>
            {t("support.heading.title.0")}
          </span>
          <span className={`${classes.text} ${classes["text--white"]}`}>
            {t("support.heading.title.1")}
          </span>
          <span className={`${classes.text} ${classes["text--white"]}`}>
            {t("support.heading.title.2")}
          </span>
          <span className={`${classes.text} ${classes["text--blue"]}`}>
            {t("support.heading.title.3")}
          </span>
          <span className={`${classes.text} ${classes["text--blue"]}`}>
            {t("support.heading.title.4")}
          </span>{" "}
          <span className={`${classes.text} ${classes["text--blue"]}`}>
            {t("support.heading.title.5")}
          </span>
        </div>

        <div className={classes["text-block--850"]}>
          <span className={`${classes.text} ${classes["text--white"]}`}>
            {t("support.heading.title.0")} {t("support.heading.title.1")}
          </span>
          <span className={`${classes.text} ${classes["text--white"]}`}>
            {t("support.heading.title.2")}
          </span>
          <div className={classes["blue-text-block"]}>
            <span className={`${classes.text} ${classes["text--blue"]}`}>
              {t("support.heading.title.3")} {t("support.heading.title.4")}
            </span>
            <span className={`${classes.text} ${classes["text--blue"]}`}>
              {t("support.heading.title.5")}
            </span>
          </div>
        </div>
      </div>

      <div className={classes.actions}>
        <p className={classes.text}>{t("support.heading.text")}</p>
        <button
          className={classes.button}
          onClick={scheduleConsultationHandler}
        >
          {t("support.heading.buttonText")}
        </button>
      </div>
    </div>
  );
};

export default Heading;
