import React from "react";
import classes from "./Strategy.module.scss";
import { useTranslation } from "react-i18next";
const chartImage = require("../../../media/IT-consulting/chart.png");
const codeImage = require("../../../media/IT-consulting/code.png");
const meetingImage = require("../../../media/IT-consulting/meeting.png");
const dotsMobileImage = require("../../../media/IT-consulting/dots-mobile.png");
const dots768ImageFirst = require("../../../media/IT-consulting/dots-768--1.png");
const dots768ImageSecond = require("../../../media/IT-consulting/dots-768--2.png");
const dots768ImageThird = require("../../../media/IT-consulting/dots-768--3.png");
const dots1440ImageFirst = require("../../../media/IT-consulting/dots-1440--1.png");
const dots1440ImageSecond = require("../../../media/IT-consulting/dots-1440--2.png");
const dots1440ImageThird = require("../../../media/IT-consulting/dots-1440--3.png");

const Strategy = () => {
  const { t } = useTranslation();

  const iconsList = [
    { id: 1, icon: "🤝", text: t("consulting.strategy.iconsList.0") },
    { id: 2, icon: "💸", text: t("consulting.strategy.iconsList.1") },
    { id: 3, icon: "🖥️", text: t("consulting.strategy.iconsList.2") },
    { id: 4, icon: "📋", text: t("consulting.strategy.iconsList.3") },
    { id: 5, icon: "📈", text: t("consulting.strategy.iconsList.4") },
  ];

  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes["header-container"]}>
          <h2 className={classes.header}>
            {t("consulting.strategy.firstHeader")}
          </h2>
          <p className={classes.text}>{t("consulting.strategy.firstText")}</p>
        </div>
        <div className={classes["images-container"]}>
          <div className={classes["image-container"]}>
            <img className={classes.image} src={meetingImage} alt="Meeting" />
            <img
              className={classes["dots-image--mobile"]}
              src={dotsMobileImage}
              alt="Dots background"
            />
            <img
              className={classes["dots-image--768"]}
              src={dots768ImageFirst}
              alt="Dots background"
            />{" "}
            <img
              className={classes["dots-image--1440"]}
              src={dots1440ImageFirst}
              alt="Dots background"
            />
          </div>
          <div className={classes["image-container"]}>
            <img className={classes.image} src={codeImage} alt="Code" />
            <img
              className={classes["dots-image--mobile"]}
              src={dotsMobileImage}
              alt="Dots background"
            />
            <img
              className={classes["dots-image--768"]}
              src={dots768ImageSecond}
              alt="Dots background"
            />{" "}
            <img
              className={classes["dots-image--1440"]}
              src={dots1440ImageSecond}
              alt="Dots background"
            />
          </div>
          <div className={classes["image-container"]}>
            <img className={classes.image} src={chartImage} alt="Chart" />
            <img
              className={classes["dots-image--mobile"]}
              src={dotsMobileImage}
              alt="Dots background"
            />
            <img
              className={classes["dots-image--768"]}
              src={dots768ImageThird}
              alt="Dots background"
            />
            <img
              className={classes["dots-image--1440"]}
              src={dots1440ImageThird}
              alt="Dots background"
            />
          </div>
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes["header-container"]}>
          <h2 className={classes.header}>
            {t("consulting.strategy.secondHeader")}
          </h2>
          <p className={classes.text}>{t("consulting.strategy.secondText")}</p>
        </div>

        <div className={classes["icons-list"]}>
          {iconsList.map(({ icon, text, id }) => (
            <div key={id} className={classes["icon-container"]}>
              <span className={classes.icon}>{icon}</span>
              <span className={classes.text}>{text}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Strategy;
