import React, { useEffect } from "react";
import classes from "./Cybertracker.module.scss";
import Heading from "../../components/caseStudy/heading/Heading.tsx";
import Info from "../../components/caseStudy/info/Info.tsx";
import logo from "../../media/case-study/cybertracker-logo.svg";
import AppDescription from "../../components/caseStudy/app-description/AppDescription.tsx";
import OtherProjects from "../../components/caseStudy/other-projects/OtherProjects.tsx";
import { ECaseStudy } from "../../enums/case-study.enum.ts";
import { useTranslation } from "react-i18next";

const Cybertracker = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const appDescriptionText = {
    left: {
      firstList: {
        header: t(
          "caseStudy.cybertracker.appDescriptionText.left.firstList.header",
        ),
        list: [
          {
            index: 1,
            text: t(
              "caseStudy.cybertracker.appDescriptionText.left.firstList.list.0",
            ),
          },
          {
            index: 2,
            text: t(
              "caseStudy.cybertracker.appDescriptionText.left.firstList.list.1",
            ),
          },
          {
            index: 3,
            text: t(
              "caseStudy.cybertracker.appDescriptionText.left.firstList.list.2",
            ),
          },
          {
            index: 4,
            text: t(
              "caseStudy.cybertracker.appDescriptionText.left.firstList.list.3",
            ),
          },
        ],
      },
      secondList: {
        header: t(
          "caseStudy.cybertracker.appDescriptionText.left.secondList.header",
        ),
        list: [
          {
            index: 1,
            text: t(
              "caseStudy.cybertracker.appDescriptionText.left.secondList.list.0",
            ),
          },
          {
            index: 2,
            text: t(
              "caseStudy.cybertracker.appDescriptionText.left.secondList.list.1",
            ),
          },
          {
            index: 3,
            text: t(
              "caseStudy.cybertracker.appDescriptionText.left.secondList.list.2",
            ),
          },
          {
            index: 4,
            text: t(
              "caseStudy.cybertracker.appDescriptionText.left.secondList.list.3",
            ),
          },
          {
            index: 5,
            text: t(
              "caseStudy.cybertracker.appDescriptionText.left.secondList.list.4",
            ),
          },
        ],
      },
      iconsList: {
        header: t(
          "caseStudy.cybertracker.appDescriptionText.left.iconsList.header",
        ),
        list: [
          {
            index: 1,
            icon: "🗒️",
            text: t(
              "caseStudy.cybertracker.appDescriptionText.left.iconsList.list.0",
            ),
          },
          {
            index: 2,
            icon: "🔐",
            text: t(
              "caseStudy.cybertracker.appDescriptionText.left.iconsList.list.1",
            ),
          },
          {
            index: 3,
            icon: "📄️",
            text: t(
              "caseStudy.cybertracker.appDescriptionText.left.iconsList.list.2",
            ),
          },
        ],
      },
      thirdList: null,
    },
    right: {
      image: require("../../media/projects/cybertracker.png"),
      text: {
        title: [
          t("caseStudy.cybertracker.appDescriptionText.right.title.0"),
          t("caseStudy.cybertracker.appDescriptionText.right.title.1"),
        ],
        body: t("caseStudy.cybertracker.appDescriptionText.right.body"),
      },
    },
  };

  const infoText = {
    header: t("caseStudy.cybertracker.info.header"),
    subheader: t("caseStudy.cybertracker.info.subheader"),
    description: t("caseStudy.cybertracker.info.description"),
  };

  const infoLabels = [
    t("caseStudy.cybertracker.info.labels.0"),
    t("caseStudy.cybertracker.info.labels.1"),
    t("caseStudy.cybertracker.info.labels.2"),
    t("caseStudy.cybertracker.info.labels.3"),
    t("caseStudy.cybertracker.info.labels.4"),
    t("caseStudy.cybertracker.info.labels.5"),
    t("caseStudy.cybertracker.info.labels.6"),
    t("caseStudy.cybertracker.info.labels.7"),
    t("caseStudy.cybertracker.info.labels.8"),
  ];

  return (
    <div className={classes.section}>
      <Heading
        textWhite={t("caseStudy.cybertracker.heading.0")}
        textBlue={t("caseStudy.cybertracker.heading.1")}
        medium={true}
      />
      <Info text={infoText} labels={infoLabels} />

      <div className={classes["image-section"]}>
        <div className={classes["overlay"]}></div>
        <div className={classes["image-container"]}>
          <img className={classes.logo} src={logo} alt="Cybertracker logo" />
          <div className={classes.text}>
            <h4 className={classes.title}>
              {t("caseStudy.cybertracker.imageSection.header")}
            </h4>
            <p className={classes.description}>
              {t("caseStudy.cybertracker.imageSection.text")}
            </p>
          </div>
        </div>
      </div>

      <AppDescription appDescriptionText={appDescriptionText} />

      <OtherProjects name={ECaseStudy.CYBERTRACKER} />
    </div>
  );
};

export default Cybertracker;
