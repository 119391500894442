import React, { useEffect, useState } from "react";
import logo from "../../media/logo.svg";
import classes from "./Navigation.module.scss";
import { Trans, useTranslation } from "react-i18next";
import i18n from "../../i18n";
import uaFlag from "../../media/icons/ua.svg";
import engFlag from "../../media/icons/eng.svg";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";

const Navigation = () => {
  const userLanguage = navigator.language;
  const [showLanguagesContainer, setShowLanguagesContainer] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const { t } = useTranslation();
  const [language, setLanguage] = useState("eng");

  useEffect(() => {
    if (userLanguage === "uk-UA") {
      i18n.changeLanguage("ua");
      setLanguage("ua");
    } else {
      i18n.changeLanguage("eng");
      setLanguage("eng");
    }
  }, []);

  const languages = {
    ua: {
      text: "UA",
      icon: uaFlag,
    },
    eng: {
      text: "ENG",
      icon: engFlag,
    },
  };

  const currentLanguage = languages[language];

  const otherLanguage = language === "ua" ? languages.eng : languages.ua;

  const openLanguagesListHandler = () => setShowLanguagesContainer(true);

  const selectLanguage = (e) => {
    const selectedLanguage = e.currentTarget.children[1].textContent;

    if (selectedLanguage === "UA") {
      i18n.changeLanguage("ua");
      setLanguage("ua");
    } else if (selectedLanguage === "ENG") {
      i18n.changeLanguage("eng");
      setLanguage("eng");
    }
    setShowLanguagesContainer(false);
    setMenuOpened(false);
  };

  const openMenuHandler = () => {
    setMenuOpened(!menuOpened);
    setShowLanguagesContainer(false);
  };

  return (
    <nav className={`${classes.navigation}`}>
      <Link to={"/"}>
        <img src={logo} alt="Logo" className={classes["navigation__logo"]} />
      </Link>

      <div className={classes["navigation__actions"]}>
        <div className={classes["language"]}>
          <div
            className={classes["language__button"]}
            onClick={openLanguagesListHandler}
          >
            <img
              alt={`${currentLanguage} icon`}
              src={currentLanguage.icon}
            ></img>
            <span className={classes["language__name"]}>
              {currentLanguage.text}
            </span>
            <span className={classes["language__triangle"]}>&#9660;</span>
          </div>
          <div
            className={`${classes["select-buttons-container"]} ${
              showLanguagesContainer ? classes["shown"] : classes["hidden"]
            }`}
          >
            <div className={classes["select-button"]} onClick={selectLanguage}>
              <img
                alt={`${currentLanguage} icon`}
                src={currentLanguage.icon}
              ></img>
              <span className={classes["language__name"]}>
                {currentLanguage.text}
              </span>
              <span className={classes["language__triangle"]}>&#9660;</span>
            </div>

            <div className={classes["line"]}></div>

            <div className={classes["select-button"]} onClick={selectLanguage}>
              <img alt={`${otherLanguage} icon`} src={otherLanguage.icon}></img>
              <span className={classes["language__name"]}>
                {otherLanguage.text}
              </span>
            </div>
          </div>
        </div>
        <ScrollLink smooth={true} to="about-us">
          {t("navigation.about-us")}
        </ScrollLink>
        <ScrollLink smooth={true} to="contact">
          {t("navigation.contact")}
        </ScrollLink>
      </div>

      <div
        className={`${classes["menu-btn"]} ${menuOpened && classes.open}`}
        onClick={openMenuHandler}
      >
        <div className={classes["menu-btn__burger"]}></div>
      </div>

      <div
        className={`${classes.menu} ${
          menuOpened ? "" : classes["menu--hidden"]
        }`}
      >
        <div className={classes["navigation__actions"]}>
          <ScrollLink
            smooth={true}
            to="about-us"
            onClick={() => setMenuOpened(false)}
          >
            {t("navigation.about-us")}
          </ScrollLink>
          <ScrollLink
            smooth={true}
            to="contact"
            onClick={() => setMenuOpened(false)}
          >
            <Trans i18nKey="navigation.contact">Contact</Trans>
          </ScrollLink>
          <div className={classes["language"]}>
            <div
              className={classes["language__button"]}
              onClick={openLanguagesListHandler}
            >
              <img
                alt={`${currentLanguage} icon`}
                src={currentLanguage.icon}
              ></img>
              <span className={classes["language__name"]}>
                {currentLanguage.text}
              </span>
              <span className={classes["language__triangle"]}>&#9660;</span>
            </div>
            <div
              className={`${classes["select-buttons-container"]} ${
                showLanguagesContainer ? classes["shown"] : classes["hidden"]
              }`}
            >
              <div
                className={classes["select-button"]}
                onClick={selectLanguage}
              >
                <img
                  alt={`${currentLanguage} icon`}
                  src={currentLanguage.icon}
                ></img>
                <span className={classes["language__name"]}>
                  {currentLanguage.text}
                </span>
                <span className={classes["language__triangle"]}>&#9660;</span>
              </div>

              <div className={classes["line"]}></div>

              <div
                className={classes["select-button"]}
                onClick={selectLanguage}
              >
                <img
                  alt={`${otherLanguage} icon`}
                  src={otherLanguage.icon}
                ></img>
                <span className={classes["language__name"]}>
                  {otherLanguage.text}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
