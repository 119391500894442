import React, { useEffect } from "react";
import classes from "./SoftwareDevelopment.module.scss";
import Heading from "./heading/Heading.tsx";
import About from "./about/About.tsx";
import Approach from "./approach/Approach.tsx";
import BuildForYou from "./build-for-you/BuildForYou.tsx";
import Industries from "./industries/Industries.tsx";
import Why from "./why/Why.tsx";
import FormCard from "../IT-consulting/form-card/FormCard.tsx";
import Services, {
  EServicesNames,
} from "../../components/services/Services.tsx";

const SoftwareDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <Heading />
      <About />
      <Approach />
      <BuildForYou />
      <Industries />
      <Why />
      <div className={classes["services-container"]}>
        <Services currentServiceName={EServicesNames.DEVELOPMENT} />
      </div>
      <FormCard />
    </main>
  );
};

export default SoftwareDevelopment;
