import React from "react";
import classes from "./OurProjects.module.scss";
import ProjectItem from "./ProjectItem.tsx";
import cyberTrackerImage from "../../media/projects/cybertracker.png";
import kyivContactCenterImage from "../../media/projects/1551.png";
import eRoadImage from "../../media/projects/e-road.png";
import eQueueImage from "../../media/projects/e-queue.png";
import rightArrowIcon from "../../media/icons/arrowRightBlack.svg";
import { useTranslation } from "react-i18next";

export interface IProject {
  id: number;
  imageUrl: string;
  title: string;
  description: string;
  path: string | null;
}

const OurProjects = () => {
  const { t } = useTranslation();

  const projects: { left: IProject[]; right: IProject[] } = {
    left: [
      {
        id: 1,
        imageUrl: cyberTrackerImage,
        title: t("ourProjects.projectTitles.cyberTracker"),
        description: t("ourProjects.projectDescriptions.cyberTracker"),
        path: "/case-study/cybertracker",
      },
      {
        id: 2,
        imageUrl: kyivContactCenterImage,
        title: t("ourProjects.projectTitles.1551"),
        description: t("ourProjects.projectDescriptions.1551"),
        path: "/case-study/1551",
      },
    ],
    right: [
      {
        id: 3,
        imageUrl: eRoadImage,
        title: t("ourProjects.projectTitles.eRoad"),
        description: t("ourProjects.projectDescriptions.eRoad"),
        path: "/case-study/e-road",
      },
      {
        id: 4,
        imageUrl: eQueueImage,
        title: t("ourProjects.projectTitles.eQueue"),
        description: t("ourProjects.projectDescriptions.eQueue"),
        path: "/case-study/e-cherha",
      },
    ],
  };

  return (
    <div className={classes["portfolio-container"]}>
      <div className={classes["projects-column"]}>
        <div className={classes["heading-container"]}>
          <h2 className={classes["portfolio-header"]}>
            {t("ourProjects.title")}
          </h2>
          {/*todo temporary hidden*/}
          <a
            href="#"
            className={classes["portfolio-link"]}
            style={{ display: "none" }}
          >
            {t("buttons.viewAll")} <img src={rightArrowIcon} alt="Arrow icon" />
          </a>
        </div>
        <div className={classes["items-container"]}>
          {projects.left.map((project) => (
            <ProjectItem
              key={project.id}
              title={project.title}
              description={project.description}
              imgUrl={project.imageUrl}
              path={project.path}
            />
          ))}
        </div>
      </div>

      <div className={classes["projects-column"]}>
        <div className={classes["items-container"]}>
          {projects.right.map((project) => (
            <ProjectItem
              key={project.id}
              title={project.title}
              description={project.description}
              imgUrl={project.imageUrl}
              path={project.path}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurProjects;
