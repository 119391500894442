import React, { useEffect } from "react";
import classes from "./E-road.module.scss";
import appDescriptionClasses from "../../components/caseStudy/app-description/AppDescription.module.scss";
import Heading from "../../components/caseStudy/heading/Heading.tsx";
import Info from "../../components/caseStudy/info/Info.tsx";
import logo from "../../media/case-study/eroad-card.png";
import AppDescription from "../../components/caseStudy/app-description/AppDescription.tsx";
import OtherProjects from "../../components/caseStudy/other-projects/OtherProjects.tsx";
import { ECaseStudy } from "../../enums/case-study.enum.ts";
import { useTranslation } from "react-i18next";

const Eroad = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const appDescriptionText = {
    left: null,
    right: {
      image: require("../../media/case-study/eroad.png"),
      text: {
        title: [
          t("caseStudy.eroad.appDescription.right.title.0"),
          t("caseStudy.eroad.appDescription.right.title.1"),
          t("caseStudy.eroad.appDescription.right.title.2"),
        ],
        body: t("caseStudy.eroad.appDescription.right.body"),
      },
    },
  };

  const infoText = {
    header: t("caseStudy.eroad.info.header"),
    subheader: t("caseStudy.eroad.info.subheader"),
    description: t("caseStudy.eroad.info.description"),
  };

  const infoLabels = [
    t("caseStudy.eroad.info.labels.0"),
    t("caseStudy.eroad.info.labels.1"),
    t("caseStudy.eroad.info.labels.2"),
    t("caseStudy.eroad.info.labels.3"),
    t("caseStudy.eroad.info.labels.4"),
    t("caseStudy.eroad.info.labels.5"),
    t("caseStudy.eroad.info.labels.6"),
    t("caseStudy.eroad.info.labels.7"),
    t("caseStudy.eroad.info.labels.8"),
  ];

  return (
    <div className={classes.section}>
      <Heading
        textWhite={t("caseStudy.eroad.heading.textWhite")}
        textBlue={t("caseStudy.eroad.heading.textBlue")}
        small={true}
      />
      <Info text={infoText} labels={infoLabels} />

      <div className={classes["image-section"]}>
        <div className={classes["overlay"]}></div>
        <div className={classes["image-container"]}>
          <img className={classes.logo} src={logo} alt="Cybertracker logo" />
          <div className={classes.text}>
            {t("caseStudy.eroad.imageSection.text")}
          </div>
        </div>
      </div>

      <AppDescription
        appDescriptionText={appDescriptionText}
        customDescription={appDescription({ t })}
      />

      <OtherProjects name={ECaseStudy.E_ROAD} />
    </div>
  );
};

export default Eroad;

const appDescription = ({ t }) => {
  return (
    <div>
      <div className={appDescriptionClasses["icons-list-block"]}>
        <h4 className={appDescriptionClasses["icons-list-block__header"]}>
          <strong>{t("caseStudy.eroad.appDescription.left.header.0")}</strong>
          {t("caseStudy.eroad.appDescription.left.header.1")}
          {t("caseStudy.eroad.appDescription.left.header.2")}{" "}
          <strong>{t("caseStudy.eroad.appDescription.left.header.3")}</strong>
        </h4>
        <div className={appDescriptionClasses["icons-list-block__list"]}>
          <div
            className={`${appDescriptionClasses["list__item"]} ${appDescriptionClasses["list-block"]}`}
          >
            <span className={appDescriptionClasses.icon}>📈</span>
            <span className={appDescriptionClasses.text}>
              {t("caseStudy.eroad.appDescription.left.firstList.header")}
            </span>
            <ul className={appDescriptionClasses["list-block__list--sm"]}>
              <li className={appDescriptionClasses.text}>
                {t("caseStudy.eroad.appDescription.left.firstList.list.0")}
              </li>
              <li className={appDescriptionClasses.text}>
                {" "}
                {t("caseStudy.eroad.appDescription.left.firstList.list.1")}
              </li>
            </ul>
          </div>

          <div
            className={`${appDescriptionClasses["list__item"]} ${appDescriptionClasses["list-block"]}`}
          >
            <span className={appDescriptionClasses.icon}>🔧</span>
            <span className={appDescriptionClasses.text}>
              {t("caseStudy.eroad.appDescription.left.secondList.header")}
            </span>
            <ul className={appDescriptionClasses["list-block__list--sm"]}>
              <li className={appDescriptionClasses.text}>
                {t("caseStudy.eroad.appDescription.left.secondList.list.0")}
              </li>
              <li className={appDescriptionClasses.text}>
                {" "}
                {t("caseStudy.eroad.appDescription.left.secondList.list.1")}
              </li>
            </ul>
          </div>

          <div
            className={`${appDescriptionClasses["list__item"]} ${appDescriptionClasses["list-block"]}`}
          >
            <span className={appDescriptionClasses.icon}>📋</span>
            <span className={appDescriptionClasses.text}>
              {t("caseStudy.eroad.appDescription.left.thirdList.header")}
            </span>
            <ul className={appDescriptionClasses["list-block__list--sm"]}>
              <li className={appDescriptionClasses.text}>
                {t("caseStudy.eroad.appDescription.left.thirdList.list.0")}
              </li>
              <li className={appDescriptionClasses.text}>
                {t("caseStudy.eroad.appDescription.left.thirdList.list.1")}
              </li>
            </ul>
          </div>

          <div
            className={`${appDescriptionClasses["list__item"]} ${appDescriptionClasses["list-block"]}`}
          >
            <span className={appDescriptionClasses.icon}>📑</span>
            <span className={appDescriptionClasses.text}>
              {t("caseStudy.eroad.appDescription.left.fourthList.header")}
            </span>
            <ul className={appDescriptionClasses["list-block__list--sm"]}>
              <li className={appDescriptionClasses.text}>
                {t("caseStudy.eroad.appDescription.left.fourthList.list.0")}
              </li>
            </ul>
          </div>

          <div
            className={`${appDescriptionClasses["list__item"]} ${appDescriptionClasses["list-block"]}`}
          >
            <span className={appDescriptionClasses.icon}>🗺️</span>
            <span className={appDescriptionClasses.text}>
              {t("caseStudy.eroad.appDescription.left.fifthList.header")}
            </span>
            <ul className={appDescriptionClasses["list-block__list--sm"]}>
              <li className={appDescriptionClasses.text}>
                {t("caseStudy.eroad.appDescription.left.fifthList.list.0")}
              </li>
            </ul>
          </div>

          <div
            className={`${appDescriptionClasses["list__item"]} ${appDescriptionClasses["list-block"]}`}
          >
            <span className={appDescriptionClasses.icon}>📊</span>
            <span className={appDescriptionClasses.text}>
              {t("caseStudy.eroad.appDescription.left.sixthList.header")}
            </span>
            <ul className={appDescriptionClasses["list-block__list--sm"]}>
              <li className={appDescriptionClasses.text}>
                {" "}
                {t("caseStudy.eroad.appDescription.left.sixthList.list.0")}
              </li>
            </ul>
          </div>

          <p className={appDescriptionClasses.paragraph}>
            {t("caseStudy.eroad.appDescription.left.paragraph")}
          </p>
        </div>
      </div>
    </div>
  );
};
