import React from "react";
import classes from "./Approach.module.scss";
import { useTranslation } from "react-i18next";

const Approach = () => {
  const { t } = useTranslation();

  const iconsList = [
    { id: 1, icon: "🔍", text: t("development.approach.listItems.0") },
    { id: 2, icon: "✏️", text: t("development.approach.listItems.1") },
    { id: 3, icon: "🖥️", text: t("development.approach.listItems.2") },
    { id: 4, icon: "🛠️", text: t("development.approach.listItems.3") },
    { id: 5, icon: "📋", text: t("development.approach.listItems.4") },
    { id: 6, icon: "👷", text: t("development.approach.listItems.5") },
  ];
  return (
    <section className={classes.section}>
      <div className={classes["header-container"]}>
        <h2 className={classes.header}>{t("development.approach.title")}</h2>
        <p className={classes.text}>{t("development.approach.text")}</p>
      </div>
      <div className={classes["icons-list"]}>
        {iconsList.map(({ icon, text, id }) => (
          <div key={id} className={classes["list-item"]}>
            <span className={classes.icon}>{icon}</span>
            <span className={classes.text}>{text}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Approach;
