import React from "react";
import classes from "./About.module.scss";
import { useTranslation } from "react-i18next";
const image = require("../../../media/software-implementation/about.png");
const dots430 = require("../../../media/software-development/dots-430.png");
const dots768 = require("../../../media/software-development/dots-768.png");
const dots1000 = require("../../../media/software-development/dots-1000.png");
const dots1440 = require("../../../media/software-development/dots-1440.png");
const dots1920 = require("../../../media/software-development/dots-1920.png");
const checkmarksImage = require("../../../media/software-implementation/checkmarks.png");

const About = () => {
  const { t } = useTranslation();

  return (
    <section className={classes.section}>
      <div className={classes["header-container"]}>
        <h2 className={classes.header}>{t("support.about.title")}</h2>
        <p className={classes.text}>{t("support.about.text")}</p>
      </div>

      <div className={classes["image-container"]}>
        <img
          className={classes.image}
          src={image}
          alt="Network communication"
        />
        <img
          className={`${classes.dots} ${classes["dots--430"]}`}
          src={dots430}
          alt="Dots background"
        />
        <img
          className={`${classes.dots} ${classes["dots--768"]}`}
          src={dots768}
          alt="Dots background"
        />
        <img
          className={`${classes.dots} ${classes["dots--1000"]}`}
          src={dots1000}
          alt="Dots background"
        />
        <img
          className={`${classes.dots} ${classes["dots--1440"]}`}
          src={dots1440}
          alt="Dots background"
        />
        <img
          className={`${classes.dots} ${classes["dots--1920"]}`}
          src={dots1920}
          alt="Dots background"
        />
      </div>

      <div
        className={`${classes["header-container"]} ${classes["header-container--2"]}`}
      >
        <img
          className={classes.checkmarks}
          src={checkmarksImage}
          alt="Checkmarks"
        />
        <h2 className={classes.header}>{t("support.about.secondTitle")}</h2>
      </div>
      <p className={`${classes.text} ${classes["text--2"]}`}>
        {t("support.about.secondText")}
      </p>
    </section>
  );
};

export default About;
