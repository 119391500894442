import React from "react";
import classes from "./OtherProjects.module.scss";
import { ECaseStudy } from "../../../enums/case-study.enum.ts";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";

const OtherProjects = ({ name }): React.FC<{ name: ECaseStudy }> => {
  const { t } = useTranslation();

  const allCards = [
    {
      name: ECaseStudy.MAYOR_1551,
      title: t("caseStudy.otherProjects.1551"),
      image: require("../../../media/case-study/1551-card.png"),
    },
    {
      name: ECaseStudy.E_ROAD,
      title: t("caseStudy.otherProjects.eroad"),
      image: require("../../../media/case-study/eroad-card.png"),
    },
    {
      name: ECaseStudy.E_CHERHA,
      title: t("caseStudy.otherProjects.echerha"),
      image: require("../../../media/case-study/echerha-card.png"),
    },
    {
      name: ECaseStudy.CYBERTRACKER,
      title: t("caseStudy.otherProjects.cybertracker"),
      image: require("../../../media/case-study/cybertracker-card.png"),
    },
  ];

  const cards = allCards.filter((card) => card.name !== name);

  return (
    <div className={classes["other-projects-container"]}>
      <h2 className={classes.title}>{t("caseStudy.otherProjects.header")}</h2>

      <div className={classes["cards-list"]}>
        {cards.map((card) => (
          <Link key={card.name} to={`/case-study/${card.name}`}>
            <div className={classes["card"]}>
              <h4 className={classes.title}>{card.title}</h4>
              <div className={classes["image-container"]}>
                <img
                  src={card.image}
                  alt={card.title}
                  className={classes["card-image"]}
                />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default OtherProjects;
