import React from "react";
import classes from "./Heading.module.scss";
import noise from "../../../media/noise.png";
import arrowBack from "../../../media/icons/arrow-left-white.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Heading = ({
  textWhite,
  textBlue,
  small = false,
  medium = false,
}): React.FC<{
  textWhite: string;
  textBlue: string;
  small?: boolean;
  medium?: boolean;
}> => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <>
      <div className={classes["background-container"]}>
        <div className={classes["container"]}>
          <div className={`${classes["circle"]} ${classes["circle--4"]}`}></div>
          <div className={`${classes["circle"]} ${classes["circle--3"]}`}></div>
          <div className={`${classes["circle"]} ${classes["circle--2"]}`}></div>
          <div className={`${classes["circle"]} ${classes["circle--1"]}`}></div>
          <img
            src={noise}
            alt={"noise background"}
            className={classes["noise"]}
          />
          <div className={classes["back-button-container"]}>
            <div className={classes["back-button"]} onClick={goBackHandler}>
              <img src={arrowBack} alt="Arrow back" />
              <span>{t("buttons.back")}</span>
            </div>
          </div>
          <div
            className={`${classes["heading"]} ${small && classes["heading--sm"]} ${medium && classes["heading--md"]}`}
          >
            <h1 className={classes["header--primary"]}>{textWhite}</h1>
            <h2 className={classes["header--secondary"]}>{textBlue}</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Heading;
