import React from "react";
import classes from "./Info.module.scss";
import { IInfoText } from "../../../interfaces/case-study.interface";
import { useTranslation } from "react-i18next";

const Info = ({
  text,
  labels,
  siteUrl = undefined,
}): React.FC<{ text: IInfoText; labels: string[]; siteUrl?: string }> => {
  const { t } = useTranslation();

  return (
    <div className={classes["container"]}>
      <div className={classes["text-container"]}>
        <div className={classes["heading"]}>
          <h3 className={classes["header"]}>{text.header}</h3>
          <span className={classes["subheader"]}>{text.subheader}</span>
        </div>
        <p className={classes["description"]}>{text.description}</p>
        {siteUrl && (
          <a className={classes.button} href={siteUrl} target="_blank">
            {t("buttons.visitSite")}
          </a>
        )}
      </div>
      <div className={classes["labels-container"]}>
        {labels.map((label) => (
          <span className={classes["label"]}>{label}</span>
        ))}
      </div>
    </div>
  );
};

export default Info;
