import React from "react";
import classes from "./AppDescription.module.scss";
import { IAppDescriptionText } from "../../../interfaces/case-study.interface";

const AppDescription = ({
  appDescriptionText,
  customDescription = undefined,
}): React.FC<{
  appDescriptionText: IAppDescriptionText;
  customDescription: unknown;
}> => {
  return (
    <div className={classes["app-description-container"]}>
      <div className={classes["left"]}>
        {!customDescription && (
          <>
            <div className={classes["list-block"]}>
              <h4 className={classes["list-block__header"]}>
                {appDescriptionText.left.firstList.header}
              </h4>
              <ul className={classes["list-block__list"]}>
                {appDescriptionText.left.firstList.list.map((item) => (
                  <li className={classes["list__item"]} key={item.index}>
                    {item.text}
                  </li>
                ))}
              </ul>
            </div>

            {appDescriptionText.left.secondList && (
              <div className={classes["list-block"]}>
                <h4 className={classes["list-block__header"]}>
                  {appDescriptionText.left.secondList.header}
                </h4>
                <ul className={classes["list-block__list"]}>
                  {appDescriptionText.left.secondList.list.map((item) => (
                    <li className={classes["list__item"]} key={item.index}>
                      {item.text}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className={classes["icons-list-block"]}>
              <h4 className={classes["icons-list-block__header"]}>
                {appDescriptionText.left.iconsList.header}
              </h4>
              <div className={classes["icons-list-block__list"]}>
                {appDescriptionText.left.iconsList.list.map((item) => (
                  <div className={classes["list__item"]} key={item.index}>
                    <span className={classes.icon}>{item.icon}</span>
                    <span className={classes.text}>{item.text}</span>
                  </div>
                ))}
              </div>
            </div>

            {appDescriptionText.left.thirdList && (
              <div className={classes["list-block"]}>
                <h4 className={classes["list-block__header"]}>
                  {appDescriptionText.left.thirdList.header}
                </h4>
                <ul className={classes["list-block__list"]}>
                  {appDescriptionText.left.thirdList.list.map((item) => (
                    <li className={classes["list__item"]} key={item.index}>
                      {item.text}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}

        {customDescription && customDescription}
      </div>

      <div className={classes["right"]}>
        <div className={classes["image-container"]}>
          <img
            className={classes.image}
            src={appDescriptionText.right.image}
            alt="Background"
          />
          <div className={classes["text-container"]}>
            <div className={classes["title"]}>
              {appDescriptionText.right.text.title.map((item, i) => (
                <span className={i === 1 && classes["title--blue"]}>
                  {item}
                </span>
              ))}
            </div>

            <div className={classes.body}>
              {appDescriptionText.right.text.body}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDescription;
