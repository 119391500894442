import React from "react";
import classes from "./Sectors.module.scss";
import doctorImage from "../../media/sectors/doctor.png";
import truckImage from "../../media/sectors/truck.png";
import countingImage from "../../media/sectors/counting.png";
import piggyBankImage from "../../media/sectors/piggy-bank.png";
import radioTowerImage from "../../media/sectors/radio-tower.png";
import SectorCard from "./SectorCard.tsx";
import { useTranslation } from "react-i18next";

export interface ISectorCard {
  image: string;
  title: string;
  text: string;
  id?: number;
}

const Sectors = () => {
  const { t } = useTranslation();

  const cards: ISectorCard[] = [
    {
      image: doctorImage,
      title: t("expertiseSectors.healthcare.title"),
      text: t("expertiseSectors.healthcare.description"),
      id: 1,
    },
    {
      image: truckImage,
      title: t("expertiseSectors.logistics.title"),
      text: t("expertiseSectors.logistics.description"),
      id: 2,
    },
    {
      image: countingImage,
      title: t("expertiseSectors.utilitiesServices.title"),
      text: t("expertiseSectors.utilitiesServices.description"),
      id: 3,
    },
    {
      image: piggyBankImage,
      title: t("expertiseSectors.financeAndBanking.title"),
      text: t("expertiseSectors.financeAndBanking.description"),
      id: 4,
    },
    {
      image: radioTowerImage,
      title: t("expertiseSectors.telecommunications.title"),
      text: t("expertiseSectors.telecommunications.description"),
      id: 5,
    },
  ];

  return (
    <div className={classes["container"]}>
      <h1 className={classes["header"]}>{t("expertiseSectors.title")}</h1>
      <div className={classes["cards-container"]}>
        {cards.map(({ image, title, text, id }) => (
          <SectorCard key={id} image={image} title={title} text={text} />
        ))}
      </div>
    </div>
  );
};

export default Sectors;
