import React from "react";
import classes from "./QuestionBlock.module.scss";
import { IQuestion } from "./FrequentlyAskedQuestions";
import plusIcon from "../../media/icons/plus-gray.svg";
import minusIcon from "../../media/icons/minus-white.svg";

interface IQuestionBlockProps extends IQuestion {
  isOpened: boolean;
  openBlock: (id: number) => void;
}

const QuestionBlock = ({
  title,
  description,
  isOpened,
  openBlock,
  id,
}): React.FC<IQuestionBlockProps> => {
  const openBlockHandler = () => {
    isOpened ? openBlock(null) : openBlock(id);
  };

  return (
    <div className={classes["question__container"]} onClick={openBlockHandler}>
      <div className={classes["question__title-container"]}>
        <h4
          className={`${classes["question__title"]} ${isOpened && classes["question__title--blue"]}`}
        >
          {title}
        </h4>
        <button
          className={`${classes["question__button"]} ${isOpened && classes["question__button--minus"]}`}
        >
          {!isOpened && (
            <img
              className={classes["question__button-icon"]}
              src={plusIcon}
              alt="plus icon"
            />
          )}
          {isOpened && (
            <img
              className={classes["question__button-icon"]}
              src={minusIcon}
              alt="minus icon"
            />
          )}
        </button>
      </div>
      <div
        className={`${classes["question__body"]}  ${!isOpened && classes["question__body--hidden"]}`}
      >
        <div className={classes["question__description"]}>{description}</div>
      </div>
    </div>
  );
};

export default QuestionBlock;
