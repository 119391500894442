import React from "react";
import classes from "./BuildForYou.module.scss";
import { useTranslation } from "react-i18next";

const BuildForYou = () => {
  const { t } = useTranslation();

  const blocks = [
    { id: 1, text: t("development.buildForYou.blocks.0") },
    { id: 2, text: t("development.buildForYou.blocks.1") },
    { id: 3, text: t("development.buildForYou.blocks.2") },
    { id: 4, text: t("development.buildForYou.blocks.3") },
    { id: 5, text: t("development.buildForYou.blocks.4") },
    { id: 6, text: t("development.buildForYou.blocks.5") },
    { id: 7, text: t("development.buildForYou.blocks.6") },
    { id: 8, text: t("development.buildForYou.blocks.7") },
    { id: 9, text: t("development.buildForYou.blocks.8") },
  ];

  return (
    <section className={classes.section}>
      <h2 className={classes.header}>{t("development.buildForYou.title")}</h2>
      <div className={classes["blocks-container"]}>
        {blocks.map(({ text, id }) => (
          <div className={classes.block} key={id}>
            {text}
          </div>
        ))}
      </div>
    </section>
  );
};

export default BuildForYou;
