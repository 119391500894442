import React, { useEffect } from "react";
import classes from "./E-cherha.module.scss";
import Heading from "../../components/caseStudy/heading/Heading.tsx";
import Info from "../../components/caseStudy/info/Info.tsx";
import logo from "../../media/case-study/echerha-logo.svg";
import AppDescription from "../../components/caseStudy/app-description/AppDescription.tsx";
import OtherProjects from "../../components/caseStudy/other-projects/OtherProjects.tsx";
import { ECaseStudy } from "../../enums/case-study.enum.ts";
import { useTranslation } from "react-i18next";

const ECherha = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const appDescriptionText = {
    left: {
      firstList: {
        header: t("caseStudy.echerha.appDescriptionText.left.firstList.header"),
        list: [
          {
            index: 1,
            text: t(
              "caseStudy.echerha.appDescriptionText.left.firstList.list.0",
            ),
          },
          {
            index: 2,
            text: t(
              "caseStudy.echerha.appDescriptionText.left.firstList.list.1",
            ),
          },
        ],
      },
      secondList: null,
      iconsList: {
        header: t("caseStudy.echerha.appDescriptionText.left.iconsList.header"),
        list: [
          {
            index: 1,
            icon: "⏱️️",
            text: t(
              "caseStudy.echerha.appDescriptionText.left.iconsList.list.0",
            ),
          },
          {
            index: 2,
            icon: "🚚",
            text: t(
              "caseStudy.echerha.appDescriptionText.left.iconsList.list.1",
            ),
          },
          {
            index: 3,
            icon: "🖥️",
            text: t(
              "caseStudy.echerha.appDescriptionText.left.iconsList.list.2",
            ),
          },
        ],
      },
      thirdList: null,
    },
    right: {
      image: require("../../media/case-study/echerha.png"),
      text: {
        title: [
          t("caseStudy.echerha.appDescriptionText.right.title.0"),
          t("caseStudy.echerha.appDescriptionText.right.title.1"),
        ],
        body: t("caseStudy.echerha.appDescriptionText.right.body"),
      },
    },
  };

  const infoText = {
    header: t("caseStudy.echerha.info.header"),
    subheader: t("caseStudy.echerha.info.subheader"),
    description: t("caseStudy.echerha.info.description"),
  };

  const infoLabels = [
    t("caseStudy.echerha.info.labels.0"),
    t("caseStudy.echerha.info.labels.1"),
    t("caseStudy.echerha.info.labels.2"),
    t("caseStudy.echerha.info.labels.3"),
    t("caseStudy.echerha.info.labels.4"),
    t("caseStudy.echerha.info.labels.5"),
    t("caseStudy.echerha.info.labels.6"),
  ];

  return (
    <div className={classes.section}>
      <Heading
        textWhite={t("caseStudy.echerha.heading.0")}
        textBlue={t("caseStudy.echerha.heading.1")}
        medium={true}
      />
      <Info
        text={infoText}
        labels={infoLabels}
        siteUrl="https://echerha.gov.ua/"
      />

      <div className={classes["image-section"]}>
        <div className={classes["overlay"]}></div>
        <div className={classes["image-container"]}>
          <img className={classes.logo} src={logo} alt="Cybertracker logo" />
          <div className={classes.text}>
            {t("caseStudy.echerha.imageSection.header")}
          </div>
        </div>
      </div>

      <AppDescription appDescriptionText={appDescriptionText} />

      <OtherProjects name={ECaseStudy.E_CHERHA} />
    </div>
  );
};

export default ECherha;
