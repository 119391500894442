import React from "react";
import classes from "./Services.module.scss";
import ProjectCard from "../project-card/ProjectCard.tsx";
import { useTranslation } from "react-i18next";

const consultingImage = require("../../media/services/consulting.png");
const developmentImage = require("../../media/services/development.png");
const supportImage = require("../../media/services/support.png");

export enum EServicesNames {
  CONSULTING = "IT-consulting",
  DEVELOPMENT = "software-development",
  SUPPORT = "software-implementation-and-support",
}

const Services = ({
  currentServiceName = null,
}): React.FC<{ currentServiceName: EServicesNames | null }> => {
  const { t } = useTranslation();

  const cards = [
    {
      name: EServicesNames.CONSULTING,
      title: t("services.list.0"),
      image: consultingImage,
    },
    {
      name: EServicesNames.DEVELOPMENT,
      title: t("services.list.1"),
      image: developmentImage,
    },
    {
      name: EServicesNames.SUPPORT,
      title: t("services.list.2"),
      image: supportImage,
    },
  ].filter(({ name }) => name !== currentServiceName);

  return (
    <section className={classes.section}>
      <h2 className={classes.header}>{t("services.title")}</h2>
      <div
        className={`${classes["cards-list"]} ${currentServiceName && classes[`grid--${cards.length}`]}`}
      >
        {cards.map(({ name, title, image }) => (
          <ProjectCard
            name={name}
            title={title}
            image={image}
            path={`/services/${name}`}
            fullWidth={!!currentServiceName}
          />
        ))}
      </div>
    </section>
  );
};

export default Services;
