import React from "react";
import classes from "./WhatWeOffer.module.scss";
import { useTranslation } from "react-i18next";
const supportImage = require("../../../media/software-implementation/what-we-offer/support.jpeg");
const modernizationImage = require("../../../media/software-implementation/what-we-offer/modernization.jpeg");
const solutionsImage = require("../../../media/software-implementation/what-we-offer/solutions.jpeg");
const trainingImage = require("../../../media/software-implementation/what-we-offer/training.jpeg");

const WhatWeOffer = () => {
  const { t } = useTranslation();
  const blocksList = [
    {
      id: 1,
      title: t("support.whatWeOffer.blocks.0.title"),
      text: t("support.whatWeOffer.blocks.0.text"),
      image: supportImage,
    },
    {
      id: 2,
      title: t("support.whatWeOffer.blocks.1.title"),
      text: t("support.whatWeOffer.blocks.1.text"),
      image: modernizationImage,
    },
    {
      id: 3,
      title: t("support.whatWeOffer.blocks.2.title"),
      text: t("support.whatWeOffer.blocks.2.text"),
      image: solutionsImage,
    },
    {
      id: 4,
      title: t("support.whatWeOffer.blocks.3.title"),
      text: t("support.whatWeOffer.blocks.3.text"),
      image: trainingImage,
    },
  ];

  return (
    <section className={classes.section}>
      <h2 className={classes.header}>{t("support.whatWeOffer.title")}</h2>
      <div className={classes["blocks-list"]}>
        {blocksList.map(({ id, title, text, image }) => (
          <div className={classes.block} key={id}>
            <img src={image} className={classes.image} alt="title" />
            <div className={classes["text-container"]}>
              <h4 className={classes.title}>{title}</h4>
              <span className={classes.text}>{text}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhatWeOffer;
