import React from "react";
import classes from "./HowWeWork.module.scss";
import progressBar from "../../../media/software-implementation/progress-bar.svg";
import { useTranslation } from "react-i18next";
const howWeWorkImage = require("../../../media/software-implementation/how-we-work.png");
const dots430 = require("../../../media/software-implementation/dots-430.png");
const dots768 = require("../../../media/software-implementation/dots-768.png");
const dots1000 = require("../../../media/software-implementation/dots-1000.png");
const dots1440 = require("../../../media/software-implementation/dots-1440.png");
const dots1920 = require("../../../media/software-implementation/dots-1920.png");

const HowWeWork = () => {
  const { t } = useTranslation();

  const iconsList = [
    {
      id: 1,
      icon: "🗓",
      title: t("support.howWeWork.iconsList.0.title"),
      text: t("support.howWeWork.iconsList.0.text"),
    },
    {
      id: 2,
      icon: "📊",
      title: t("support.howWeWork.iconsList.1.title"),
      text: t("support.howWeWork.iconsList.1.text"),
    },
    {
      id: 3,
      icon: "🛠️",
      title: t("support.howWeWork.iconsList.2.title"),
      text: t("support.howWeWork.iconsList.2.text"),
    },
    {
      id: 4,
      icon: "📋",
      title: t("support.howWeWork.iconsList.3.title"),
      text: t("support.howWeWork.iconsList.3.text"),
    },
    {
      id: 5,
      icon: "✅",
      title: t("support.howWeWork.iconsList.4.title"),
      text: t("support.howWeWork.iconsList.4.text"),
    },
  ];

  return (
    <section className={classes.section}>
      <div className={classes.left}>
        <h2 className={classes.header}>{t("support.howWeWork.title")}</h2>
        <p className={classes.text}>{t("support.howWeWork.text")}</p>
        <div className={classes["icons-list-container"]}>
          <img
            className={classes["progress-bar"]}
            alt="Progress bar"
            src={progressBar}
          />
          <div className={classes["icons-list"]}>
            {iconsList.map(({ id, icon, title, text }) => (
              <div className={classes["list-item"]} key={id}>
                <span className={classes.icon}>{icon}</span>
                <h4 className={classes.title}>{title}</h4>
                <p className={classes.text}>{text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classes.right}>
        <img
          src={howWeWorkImage}
          className={classes.image}
          alt="People working in the office"
        />
        <img
          src={dots430}
          className={`${classes.dots} ${classes["dots--430"]}`}
          alt="Dots background"
        />
        <img
          src={dots768}
          className={`${classes.dots} ${classes["dots--768"]}`}
          alt="Dots background"
        />
        <img
          src={dots1000}
          className={`${classes.dots} ${classes["dots--1000"]}`}
          alt="Dots background"
        />
        <img
          src={dots1440}
          className={`${classes.dots} ${classes["dots--1440"]}`}
          alt="Dots background"
        />
        <img
          src={dots1920}
          className={`${classes.dots} ${classes["dots--1920"]}`}
          alt="Dots background"
        />
      </div>
    </section>
  );
};

export default HowWeWork;
