import React, { useEffect } from "react";
import classes from "./Heading.module.scss";
import { useTranslation } from "react-i18next";
import Checkmarks from "./Checkmarks.tsx";
import { useDispatch } from "react-redux";
import { translationLoaded } from "../../store/loadingSlice.js";

const Heading = () => {
  const { t } = useTranslation();

  const headingMobilePieces: string[] = t("heading-mobile", {
    returnObjects: true,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof headingMobilePieces === "object") {
      dispatch(translationLoaded("heading"));
    }
  });

  return (
    <>
      <div className={`${classes.heading} ${classes["heading-desktop"]}`}>
        <div className={classes["heading__row--1"]}>
          <span>{t("heading.1")}</span>
          <Checkmarks />
        </div>
        <span className={classes["heading__row--2"]}>{t("heading.2")}</span>
        <span className={classes["heading__row--3"]}>
          <span>{t("heading.3")}</span>
          <span className={classes["text-blue"]}> {t("heading.4")}</span>
        </span>
      </div>

      <div className={`${classes.heading} ${classes["heading-mobile"]}`}>
        <>
          <span>{headingMobilePieces[0]}</span>
          <span>{headingMobilePieces[1]}</span>
          <span>{headingMobilePieces[2]}</span>
          <span> {headingMobilePieces[3]}</span>
          <span> {headingMobilePieces[4]}</span>
          <span>{headingMobilePieces[5]}</span>
        </>
        <Checkmarks />
      </div>
    </>
  );
};

export default Heading;
