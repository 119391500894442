import React, { useState } from "react";
import classes from "./FrequentlyAskedQuestions.module.scss";
import QuestionBlock from "../../../components/frequently-asked-questions/QuestionBlock.tsx";
import { useTranslation } from "react-i18next";

const FrequentlyAskedQuestions = () => {
  const [openedBlockId, setOpenedBlockId] = useState<null | number>(null);

  const { t } = useTranslation();

  const openBlockHandler = (id: number | null) => {
    setOpenedBlockId(id);
  };

  const blocks = [
    {
      title: t("consulting.frequentlyAskedQuestions.blocks.0.title"),
      description: t(
        "consulting.frequentlyAskedQuestions.blocks.0.description",
      ),
      id: 1,
    },
    {
      title: t("consulting.frequentlyAskedQuestions.blocks.1.title"),
      description: t(
        "consulting.frequentlyAskedQuestions.blocks.1.description",
      ),
      id: 2,
    },
    {
      title: t("consulting.frequentlyAskedQuestions.blocks.2.title"),
      description: t(
        "consulting.frequentlyAskedQuestions.blocks.2.description",
      ),
      id: 3,
    },
  ];

  return (
    <section className={classes.section}>
      <h1 className={classes.header}>
        {t("consulting.frequentlyAskedQuestions.header")}
      </h1>

      <div className={classes["blocks-list"]}>
        {blocks.map(({ title, description, id }) => (
          <QuestionBlock
            title={title}
            description={description}
            key={id}
            id={id}
            isOpened={id === openedBlockId}
            openBlock={openBlockHandler}
          />
        ))}
      </div>
    </section>
  );
};

export default FrequentlyAskedQuestions;
