import React from "react";
import classes from "./Industries.module.scss";
import { useTranslation } from "react-i18next";
const erpImage = require("../../../media/software-development/industries/erp.png");
const crmImage = require("../../../media/software-development/industries/crm.png");
const bimImage = require("../../../media/software-development/industries/bim.png");
const wmsImage = require("../../../media/software-development/industries/wms.png");
const ehrImage = require("../../../media/software-development/industries/ehr.png");
const utilityImage = require("../../../media/software-development/industries/utility.png");
const gisImage = require("../../../media/software-development/industries/gis.png");
const riskImage = require("../../../media/software-development/industries/risk.png");
const pacsImage = require("../../../media/software-development/industries/pacs.png");
const pacs2Image = require("../../../media/software-development/industries/pacs-2.png");
const facilityImage = require("../../../media/software-development/industries/facility.png");
const analyticsImage = require("../../../media/software-development/industries/analytics.png");
const edmsImage = require("../../../media/software-development/industries/edms.png");
const tmsImage = require("../../../media/software-development/industries/tms.png");

const Industries = () => {
  const { t } = useTranslation();
  const images = [
    { image: erpImage, id: 1, text: t("development.industries.cardsText.0") },
    { image: crmImage, id: 2, text: t("development.industries.cardsText.1") },
    { image: bimImage, id: 3, text: t("development.industries.cardsText.2") },
    { image: wmsImage, id: 4, text: t("development.industries.cardsText.3") },
    { image: ehrImage, id: 5, text: t("development.industries.cardsText.4") },
    {
      image: utilityImage,
      id: 6,
      text: t("development.industries.cardsText.5"),
    },
    { image: gisImage, id: 7, text: t("development.industries.cardsText.6") },
    { image: riskImage, id: 8, text: t("development.industries.cardsText.7") },
    { image: pacsImage, id: 9, text: t("development.industries.cardsText.8") },
    {
      image: pacs2Image,
      id: 10,
      text: t("development.industries.cardsText.9"),
    },
    {
      image: facilityImage,
      id: 11,
      text: t("development.industries.cardsText.10"),
    },
    {
      image: analyticsImage,
      id: 12,
      text: t("development.industries.cardsText.11"),
    },
    {
      image: edmsImage,
      id: 13,
      text: t("development.industries.cardsText.12"),
    },
    { image: tmsImage, id: 14, text: t("development.industries.cardsText.13") },
  ];

  return (
    <section className={classes.section}>
      <h2 className={classes.header}>{t("development.industries.title")}</h2>
      <p className={classes.text}>{t("development.industries.text")}</p>

      <div className={classes["images-block"]}>
        {images.map(({ image, id, text }) => (
          <div key={id} className={classes["image-container"]}>
            <img src={image} alt={text} />
            <span className={classes.text}>{text}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Industries;
