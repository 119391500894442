import "./App.scss";
import Navigation from "./components/navigation/Navigation.tsx";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/home/Home.tsx";
import React from "react";
import Cybertracker from "./routes/cybertracker/Cybertracker.tsx";
import Footer from "./components/footer/Footer.tsx";
import Mayor1551 from "./routes/mayor-1551/Mayor1551.tsx";
import ECherha from "./routes/e-cherha/E-cherha.tsx";
import Eroad from "./routes/e-road/E-road.tsx";
import ITConsulting from "./routes/IT-consulting/IT-consulting.tsx";
import { EServicesNames } from "./components/services/Services.tsx";
import SoftwareDevelopment from "./routes/software-development/SoftwareDevelopment.tsx";
import SoftwareImplementation from "./routes/software-implementation/SoftwareImplementation.tsx";

function App() {
  localStorage.setItem("language", JSON.stringify("eng"));
  return (
    <div>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/case-study/cybertracker" element={<Cybertracker />} />
        <Route path="/case-study/1551" element={<Mayor1551 />} />
        <Route path="/case-study/e-cherha" element={<ECherha />} />
        <Route path="/case-study/e-road" element={<Eroad />} />
        <Route
          path={`/services/${EServicesNames.CONSULTING}`}
          element={<ITConsulting />}
        />
        <Route
          path={`/services/${EServicesNames.DEVELOPMENT}`}
          element={<SoftwareDevelopment />}
        />
        <Route
          path={`/services/${EServicesNames.SUPPORT}`}
          element={<SoftwareImplementation />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
