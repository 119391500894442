import React, { useState } from "react";
import classes from "./FrequentlyAskedQuestions.module.scss";
import QuestionBlock from "./QuestionBlock.tsx";
import { useTranslation } from "react-i18next";

export interface IQuestion {
  title: string;
  description: string;
  id: number;
}

const FrequentlyAskedQuestions = () => {
  const { t } = useTranslation();

  const questions: { left: IQuestion[]; right: IQuestion[] } = {
    left: [
      {
        title: t("frequentlyAskedQuestions.blocks.0.title"),
        description: t("frequentlyAskedQuestions.blocks.0.body"),
        id: 1,
      },
      {
        title: t("frequentlyAskedQuestions.blocks.1.title"),
        description: t("frequentlyAskedQuestions.blocks.1.body"),
        id: 2,
      },
      {
        title: t("frequentlyAskedQuestions.blocks.2.title"),
        description: t("frequentlyAskedQuestions.blocks.2.body"),
        id: 3,
      },
    ],
    right: [
      {
        title: t("frequentlyAskedQuestions.blocks.3.title"),
        description: t("frequentlyAskedQuestions.blocks.3.body"),
        id: 4,
      },
      {
        title: t("frequentlyAskedQuestions.blocks.4.title"),
        description: t("frequentlyAskedQuestions.blocks.4.body"),
        id: 5,
      },
      {
        title: t("frequentlyAskedQuestions.blocks.5.title"),
        description: t("frequentlyAskedQuestions.blocks.5.body"),
        id: 6,
      },
      {
        title: t("frequentlyAskedQuestions.blocks.6.title"),
        description: t("frequentlyAskedQuestions.blocks.6.body"),
        id: 7,
      },
    ],
  };

  const [openedBlockId, setOpenedBlockId] = useState<null | number>(null);

  const openBlockHandler = (id: number | null) => {
    setOpenedBlockId(id);
  };

  return (
    <div className={classes["container"]}>
      <h1 className={classes["header"]}>
        {t("frequentlyAskedQuestions.title")}
      </h1>
      <div className={classes["blocks-container"]}>
        <div className={classes["left"]}>
          {questions.left.map(({ title, description, id }) => (
            <QuestionBlock
              title={title}
              description={description}
              key={id}
              id={id}
              isOpened={id === openedBlockId}
              openBlock={openBlockHandler}
            />
          ))}
        </div>
        <div className={classes["right"]}>
          {questions.right.map(({ title, description, id }) => (
            <QuestionBlock
              title={title}
              description={description}
              key={id}
              id={id}
              isOpened={id === openedBlockId}
              openBlock={openBlockHandler}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
