import React from "react";
import classes from "./Why.module.scss";
import diamondIcon from "../../../media/software-development/icons/diamond.svg";
import wrenchIcon from "../../../media/software-development/icons/wrench.svg";
import schemeIcon from "../../../media/software-development/icons/scheme.svg";
import { useTranslation } from "react-i18next";

const Why = () => {
  const { t } = useTranslation();

  const cards = [
    { id: 1, icon: diamondIcon, text: t("development.why.blocksText.0") },
    { id: 2, icon: wrenchIcon, text: t("development.why.blocksText.1") },
    { id: 3, icon: schemeIcon, text: t("development.why.blocksText.2") },
  ];

  return (
    <section className={classes.section}>
      <h2 className={classes.header}>{t("development.why.title")}</h2>
      <div className={classes["cards-list"]}>
        {cards.map(({ id, icon, text }) => (
          <div key={id} className={classes.card}>
            <img src={icon} alt={text} className={classes.icon} />
            <span className={classes.text}>{text}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Why;
