import React from "react";
import classes from "./Why.module.scss";
import buildingIcon from "../../../media/software-implementation/why/building.svg";
import diamondIcon from "../../../media/software-implementation/why/diamond.svg";
import chartIcon from "../../../media/software-implementation/why/chart.svg";
import { useTranslation } from "react-i18next";

const Why = () => {
  const { t } = useTranslation();

  const blocksList = [
    {
      id: 1,
      text: t("support.why.blocks.0"),
      icon: buildingIcon,
      alt: "Building icon",
    },
    {
      id: 2,
      text: t("support.why.blocks.0"),
      icon: diamondIcon,
      alt: "Diamond icon",
    },
    {
      id: 3,
      text: t("support.why.blocks.0"),
      icon: chartIcon,
      alt: "Chart icon",
    },
  ];

  return (
    <section className={classes.section}>
      <h2 className={classes.header}>{t("support.why.title")}</h2>

      <div className={classes["blocks-list"]}>
        {blocksList.map(({ id, icon, text, alt }) => (
          <div className={classes.block} key={id}>
            <img className={classes.icon} alt={alt} src={icon} />
            <span className={classes.text}>{text}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Why;
