import React from "react";
import classes from "./WhyChooseUs.module.scss";
import { useTranslation } from "react-i18next";

const WhyChooseUs = () => {
  const { t } = useTranslation();
  const blocks = [
    {
      id: 1,
      title: t("consulting.whyChooseUs.blocks.0.title"),
      text: t("consulting.whyChooseUs.blocks.0.text"),
    },
    {
      id: 2,
      title: t("consulting.whyChooseUs.blocks.1.title"),
      text: t("consulting.whyChooseUs.blocks.1.text"),
    },
    {
      id: 3,
      title: t("consulting.whyChooseUs.blocks.2.title"),
      text: t("consulting.whyChooseUs.blocks.2.text"),
    },
    {
      id: 4,
      title: t("consulting.whyChooseUs.blocks.3.title"),
      text: t("consulting.whyChooseUs.blocks.3.text"),
    },
    {
      id: 5,
      title: t("consulting.whyChooseUs.blocks.4.title"),
      text: t("consulting.whyChooseUs.blocks.4.text"),
    },
  ];

  return (
    <section className={classes.container}>
      <h2 className={classes.header}>{t("consulting.whyChooseUs.header")}</h2>

      <div className={classes["blocks-list"]}>
        {blocks.map(({ id, title, text }) => (
          <div key={id} className={classes.block}>
            <h4 className={classes["block__title"]}>{title}</h4>{" "}
            <p className={classes["block__text"]}>{text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyChooseUs;
