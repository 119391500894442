import React, { useEffect } from "react";
import classes from "./IT-consulting.module.scss";
import Heading from "./heading/Heading.tsx";
import Strategy from "./strategy/Strategy.tsx";
import WhyChooseUs from "./why-choose-us/WhyChooseUs.tsx";
import FrequentlyAskedQuestions from "./frequently-asked-questions/FrequentlyAskedQuestions.tsx";
import FormCard from "./form-card/FormCard.tsx";
import { useTranslation } from "react-i18next";
import Services, {
  EServicesNames,
} from "../../components/services/Services.tsx";

const ITConsulting = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.section}>
      <Heading text={t("consulting.heading.text")} />
      <Strategy />
      <WhyChooseUs />
      <FrequentlyAskedQuestions />
      <div className={classes["services-container"]}>
        <Services currentServiceName={EServicesNames.CONSULTING} />
      </div>
      <FormCard />
    </div>
  );
};

export default ITConsulting;
