import React from "react";
import classes from "./Heading.module.scss";
import noise from "../../../media/noise.png";
import arrowBack from "../../../media/icons/arrow-left-white.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";

interface HeadingText {
  type: "white" | "blue";
  word: string;
}

interface HeadingProps {
  headingText: HeadingText[];
  text: string;
}

const Heading = ({ text }): React.FC<HeadingProps> => {
  const navigate = useNavigate();

  const goBackHandler = () => {
    navigate(-1);
  };

  const scheduleConsultationHandler = () => {
    scroller.scrollTo("form", {
      smooth: true,
      offset: -50,
      duration: 800,
    });
  };

  const { t, i18n } = useTranslation();

  return (
    <div className={classes["heading-container"]}>
      <div className={`${classes["circle"]} ${classes["circle--4"]}`}></div>
      <div className={`${classes["circle"]} ${classes["circle--3"]}`}></div>
      <div className={`${classes["circle"]} ${classes["circle--2"]}`}></div>
      <div className={`${classes["circle"]} ${classes["circle--1"]}`}></div>
      <img src={noise} alt={"noise background"} className={classes["noise"]} />
      <div className={classes["back-button-container"]}>
        <div className={classes["back-button"]} onClick={goBackHandler}>
          <img src={arrowBack} alt="Arrow back" />
          <span>{t("buttons.back")}</span>
        </div>
      </div>
      {/*sorry for this code, design wasn't better*/}
      <div
        className={`${classes["heading"]} ${i18n.language === "ua" && classes.ua}`}
      >
        {i18n.language !== "ua" && (
          <div className={classes["text-block--850--2"]}>
            <span className={`${classes.text}   ${classes["text--white"]}`}>
              {t("consulting.heading.header.primary.0")}{" "}
              {t("consulting.heading.header.primary.1")}{" "}
              {t("consulting.heading.header.primary.2")}
            </span>
          </div>
        )}

        {i18n.language === "ua" && (
          <div className={classes["text-block--850--2"]}>
            <span className={`${classes.text} ${classes["text--white"]}`}>
              {t("consulting.heading.header.primary.0")}{" "}
              {t("consulting.heading.header.primary.1")}{" "}
              {t("consulting.heading.header.primary.2")}
            </span>
          </div>
        )}

        <span
          className={`${classes.text}   ${classes["text--1500"]} ${classes["text--white"]}`}
        >
          {t("consulting.heading.header.primary.0")}{" "}
          {t("consulting.heading.header.primary.1")}{" "}
          {t("consulting.heading.header.primary.2")}{" "}
          {t("consulting.heading.header.primary.3")}{" "}
          {t("consulting.heading.header.primary.4")}
        </span>

        {i18n.language !== "ua" && (
          <>
            <span
              className={`${classes.text} ${classes["text--1"]} ${classes["text--white"]}`}
            >
              {t("consulting.heading.header.primary.0")}{" "}
              {t("consulting.heading.header.primary.1")}
            </span>
            <span
              className={`${classes.text} ${classes["text--2"]} ${classes["text--2"]} ${classes["text--white"]}`}
            >
              {t("consulting.heading.header.primary.2")}
            </span>
          </>
        )}

        {i18n.language === "ua" && (
          <>
            <span
              className={`${classes.text} ${classes["text--1"]} ${classes["text--white"]}`}
            >
              {t("consulting.heading.header.primary.0")}{" "}
            </span>
            <span
              className={`${classes.text} ${classes["text--2"]} ${classes["text--2"]} ${classes["text--white"]}`}
            >
              {t("consulting.heading.header.primary.1")}{" "}
              {t("consulting.heading.header.primary.2")}
            </span>
          </>
        )}

        <span
          className={`${classes.text} ${classes["text--850--2"]} ${classes["text--white"]}`}
        >
          {t("consulting.heading.header.primary.3")}{" "}
          {t("consulting.heading.header.primary.4")}
        </span>

        {i18n.language !== "ua" && (
          <>
            <span
              className={`${classes.text} ${classes["text--3"]} ${classes["text--white"]}`}
            >
              {t("consulting.heading.header.primary.3")}
            </span>
            <span
              className={`${classes.text} ${classes["text--4"]} ${classes["text--white"]}`}
            >
              {t("consulting.heading.header.primary.4")}
            </span>
          </>
        )}

        {i18n.language === "ua" && (
          <>
            <div className={classes.row}>
              <span
                className={`${classes.text} ${classes["text--3"]} ${classes["text--white"]}`}
              >
                {t("consulting.heading.header.primary.3")}
              </span>

              <span
                className={`${classes.text} ${classes["text--4"]} ${classes["text--white"]}`}
              >
                {t("consulting.heading.header.primary.4")}
              </span>
            </div>
          </>
        )}
        <span
          className={`${classes.text} ${classes["text--5"]} ${classes["text--blue"]}`}
        >
          {t("consulting.heading.header.secondary.0")}{" "}
          {t("consulting.heading.header.secondary.1")}
        </span>
        <div className={classes["text-block--700"]}>
          <span className={`${classes.text} ${classes["text--white"]}`}>
            {t("consulting.heading.header.primary.4")}
          </span>
          <span className={`${classes.text} ${classes["text--blue"]}`}>
            {t("consulting.heading.header.secondary.0")}
          </span>
        </div>
        <span
          className={`${classes.text} ${classes["text--7"]} ${classes["text--blue"]}`}
        >
          {t("consulting.heading.header.secondary.2")}
        </span>
        <span
          className={`${classes.text} ${classes["text--700"]} ${classes["text--blue"]}`}
        >
          {t("consulting.heading.header.secondary.1")}{" "}
          {t("consulting.heading.header.secondary.2")}
        </span>
        <span
          className={`${classes.text} ${classes["text--850"]} ${classes["text--blue"]}`}
        >
          {t("consulting.heading.header.secondary.0")}{" "}
          {t("consulting.heading.header.secondary.1")}{" "}
          {t("consulting.heading.header.secondary.2")}
        </span>
      </div>
      <div className={classes.actions}>
        <p className={classes.text}>{text}</p>
        <button
          className={classes.button}
          onClick={scheduleConsultationHandler}
        >
          {t("consulting.heading.buttonText")}
        </button>
      </div>
    </div>
  );
};

export default Heading;
