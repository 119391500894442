import React from "react";
import Heading from "../../components/heading/Heading.tsx";
import AboutUs from "../../components/aboutUs/AboutUs.tsx";
import OurProjects from "../../components/our-projects/OurProjects.tsx";
// import Partners from "../../components/partners/Partners.tsx";
import WorkStages from "../../components/work-stages/WorkStages.tsx";
import Sectors from "../../components/sectors/Sectors.tsx";
import LifeCycle from "../../components/life-cycle/LifeCycle.tsx";
import FrequentlyAskedQuestions from "../../components/frequently-asked-questions/FrequentlyAskedQuestions.tsx";
import FormCard from "../../components/formCard/FormCard.tsx";
import noise from "../../media/noise.png";
import Services from "../../components/services/Services.tsx";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { i18n } = useTranslation();

  return (
    <div className="App">
      <section className="section--1">
        <div className="circle circle--4"></div>
        <div className="circle circle--3"></div>
        <div className="circle circle--2"></div>
        <div className="circle circle--1"></div>
        <img src={noise} alt={"noise background"} className="noise" />
        <Heading />
      </section>

      <section className="section--white">
        <AboutUs />
        <OurProjects />
      </section>

      {/*<section className="section--light-gray">*/}
      {/*  <Partners />*/}
      {/*</section>*/}

      <section className="section--dark-gray">
        <WorkStages />
      </section>

      <section className="section--white">
        <Sectors />
        <LifeCycle />
        {i18n.language === "eng" && <Services />}
      </section>

      <section className="section--light-gray">
        <FrequentlyAskedQuestions />
      </section>

      <section className="section--white">
        <FormCard />
      </section>
    </div>
  );
};

export default Home;
