import React from "react";
import classes from "./LifeCycle.module.scss";
import { IProjectStage } from "./LifeCycle";

const ProjectStage = ({
  icon,
  title,
  description,
}): React.FC<IProjectStage> => {
  return (
    <div className={classes["stage-item__container"]}>
      <span className={classes["stage-item__icon"]}>{icon}</span>
      <h3 className={classes["stage-item__title"]}>{title}</h3>
      <p className={classes["stage-item__description"]}>{description}</p>
    </div>
  );
};

export default ProjectStage;
