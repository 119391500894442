import React from "react";
import classes from "./ProjectCard.module.scss";
import { Link } from "react-router";

const ProjectCard = ({
  name,
  title,
  image,
  path,
  fullWidth = false,
}): React.FC<{
  name: string;
  title: string;
  image: string;
  path: string;
  fullWidth: boolean;
}> => {
  return (
    <Link key={name} to={path} className={classes["card-container"]}>
      <div className={`${classes["card"]} ${fullWidth && classes.full}`}>
        <h4 className={classes.title}>{title}</h4>
        <div className={classes["image-container"]}>
          <img src={image} alt={title} className={classes["card-image"]} />
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;
