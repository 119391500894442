import React, { useEffect, useState } from "react";
import classes from "./FormCard.module.scss";
import arrowIcon from "../../../media/icons/arrowRight.svg";
import Calendar from "react-calendar";
import "./Calendar.scss";
import { useTranslation } from "react-i18next";
import Form from "../../../components/formCard/Form.tsx";
import arrowLeft from "../../../media/icons/arrow-left-white.svg";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const FormCard = () => {
  const [inputsValue, setInputsValue] = useState({ date: "" });
  const [value, onChange] = useState<Value>(new Date());
  const [firstStep, setFirstStep] = useState(true);
  const { t, i18n } = useTranslation();

  const localeMap: Record<string, string> = {
    eng: "en-US",
    ua: "uk-UA",
  };

  const currentLocale = localeMap[i18n.language] || "en-US";

  useEffect(() => {
    if (value) {
      const formattedDate = Array.isArray(value)
        ? value.map((date) => (date ? formatDate(date) : "")).join("-")
        : value instanceof Date
          ? formatDate(value)
          : "";

      setInputsValue((prevValues) => ({
        ...prevValues,
        date: formattedDate,
      }));
    }
  }, [value]);

  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}/${month}/${day}`;
  };

  const nextStepHandler = () => {
    setFirstStep(false);
  };

  const chooseDifferentDateHandler = () => {
    setFirstStep(true);
  };

  const labels = [
    t("consulting.form.labels.0"),
    t("consulting.form.labels.1"),
    t("consulting.form.labels.2"),
    t("consulting.form.labels.3"),
    t("consulting.form.labels.4"),
    t("consulting.form.labels.5"),
  ];

  return (
    <section className={classes.section} id="form">
      <div className={classes.card}>
        <div className={classes.circle}></div>
        <div className={classes.left}>
          <div className={classes["text-block"]}>
            <div className={classes["header-container"]}>
              <h2 className={classes.header}>
                {t("consulting.form.header")}
                <img
                  className={classes["text-arrow"]}
                  src={arrowIcon}
                  alt="Arrow icon"
                />
              </h2>
              <img
                className={classes["arrow"]}
                src={arrowIcon}
                alt="Arrow icon"
              />
            </div>

            <div className={classes["info-container"]}>
              <p className={classes.message}> {t("consulting.form.message")}</p>
              <p className={classes["info-text"]}>
                {t("consulting.form.text")}
              </p>
              <p className={classes["email"]}>info@go-te.co</p>
            </div>
          </div>
        </div>
        <div className={classes.right}>
          {!firstStep && (
            <div className={classes["chosen-date-container"]}>
              <div
                className={classes["text-container"]}
                onClick={chooseDifferentDateHandler}
              >
                <img src={arrowLeft} alt="Arrow back icon" />
                <span>{t("consulting.form.chooseDateText")}</span>
              </div>

              <div className={classes.date}>{inputsValue.date}</div>
            </div>
          )}
          {firstStep && (
            <input
              type="text"
              placeholder="Choose Date*"
              className={`${classes["date-input"]}`}
              name="email"
              value={inputsValue.date}
              disabled
            />
          )}

          {firstStep && (
            <Calendar
              onChange={onChange}
              value={value}
              locale={currentLocale}
            />
          )}
          {!firstStep && <Form formLabels={labels} fullWidth={true} />}

          {firstStep && (
            <div className={classes["button-container"]}>
              <button
                className={classes.button}
                disabled={!value}
                onClick={nextStepHandler}
              >
                {t("consulting.form.buttonText")}
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default FormCard;
