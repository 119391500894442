import React from "react";
import classes from "./LifeCycle.module.scss";
import ProjectStage from "./ProjectStage.tsx";
import { useTranslation } from "react-i18next";

export interface IProjectStage {
  icon: string;
  title: string;
  description: string;
}

const LifeCycle = () => {
  const { t } = useTranslation();

  const projectStages: IProjectStage[] = [
    {
      icon: "💡",
      title: t("projectsLifeCycle.concept.title"),
      description:
        "Наші бізнес-аналітики проведуть детальний аналіз ваших потреб і цілей, створивши рішення, яке максимально відповідає вашим вимогам.",
    },
    {
      icon: "🛠️",
      title: t("projectsLifeCycle.development.title"),
      description:
        "Ми забезпечуємо комплексну розробку програмного забезпечення, включаючи дизайн, розробку та тестування продукту, який відповідає потребам вашого бізнесу.",
    },
    {
      icon: "🚀",
      title: t("projectsLifeCycle.launchAndTest.title"),
      description:
        "Ми проведемо комплексне тестування вашого рішення і забезпечимо успішний запуск системи, включаючи впровадження та налаштування.",
    },
    {
      icon: "🖥",
      title: t("projectsLifeCycle.support.title"),
      description:
        "Ми надаємо постійну технічну підтримку та супровід, щоб забезпечити безперебійну роботу вашого програмного забезпечення та оперативно вирішити будь-які проблеми.",
    },
  ];

  return (
    <div className={classes["container"]}>
      <div className={classes["heading-container"]}>
        <h1 className={classes["header"]}>{t("projectsLifeCycle.title")}</h1>
        <p className={classes["subheader"]}>
          {t("projectsLifeCycle.subTitle")}
        </p>
      </div>

      <div className={classes["stages-list"]}>
        {projectStages.map(({ icon, title, description }) => (
          <ProjectStage
            icon={icon}
            title={title}
            description={description}
            key={title}
          />
        ))}
      </div>
    </div>
  );
};

export default LifeCycle;
