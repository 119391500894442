import React from "react";
import classes from "./OurProjects.module.scss";
import { IProject } from "./OurProjects";
import rightArrowIcon from "../../media/icons/arrowRightBlack.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ProjectItem = ({
  imgUrl,
  title,
  description,
  path,
}): React.FC<IProject> => {
  const { t } = useTranslation();

  return (
    <div className={classes["project-item"]}>
      <div className={classes["project-image-container"]}>
        <img
          src={imgUrl}
          alt="Project image"
          className={classes["project-image"]}
        />
      </div>
      <h3 className={classes["project-title"]}>{title}</h3>
      <p className={classes["project-description"]}>{description}</p>
      <Link to={path} className={classes["project-link"]}>
        {t("buttons.learnMore")} <img src={rightArrowIcon} alt="Arrow icon" />
      </Link>
    </div>
  );
};

export default ProjectItem;
