import React from "react";
import classes from "./KeyFocus.module.scss";
import mechanismIcon from "../../../media/software-implementation/key-focus/mechanism.svg";
import cloudIcon from "../../../media/software-implementation/key-focus/cloud.svg";
import lockIcon from "../../../media/software-implementation/key-focus/lock.svg";
import { useTranslation } from "react-i18next";

const KeyFocus = () => {
  const { t } = useTranslation();

  const blocks = [
    {
      id: 1,
      icon: mechanismIcon,
      title: t("support.keyFocus.blocks.0.title"),
      text: t("support.keyFocus.blocks.0.text"),
    },
    {
      id: 2,
      icon: cloudIcon,
      title: t("support.keyFocus.blocks.1.title"),
      text: t("support.keyFocus.blocks.1.text"),
    },
    {
      id: 1,
      icon: lockIcon,
      title: t("support.keyFocus.blocks.2.title"),
      text: t("support.keyFocus.blocks.2.text"),
    },
  ];

  return (
    <section className={classes.section}>
      <h2 className={classes.header}>{t("support.keyFocus.title")}</h2>
      <div className={classes["blocks-container"]}>
        {blocks.map(({ id, icon, text, title }) => (
          <div className={classes.block} key={id}>
            <img className={classes.icon} src={icon} alt={title} />
            <div className={classes["text-container"]}>
              <h3 className={classes.title}>{title}</h3>
              <p className={classes.text}>{text}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default KeyFocus;
